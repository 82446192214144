var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"70%"},scopedSlots:_vm._u([{key:"default",fn:function({ isActive }){return [_c('v-card',[_c('v-card-title',[_vm._v("Assign Data FF ")]),_c('v-card-text',{staticClass:"ff-email-wrapper"},[_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.ffData)?_c('v-row',{staticClass:"assign-ff-wrapper"},[_c('v-col',{staticClass:"form-separator",attrs:{"lg":"12"}},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('p',{staticClass:"mb-0"},[_vm._v("Pemetaan FC FF")]),_c('v-btn',{staticClass:"add-button ml-2",attrs:{"variant":"success","small":""},on:{"click":function($event){return _vm.addFcFf()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1)]),_vm._l((_vm.fc_ff),function(fcff,i){return _c('v-col',{key:'fcff-' + i,attrs:{"lg":"12"}},[_c('v-row',{staticClass:"mx-3 bg-grey"},[_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                        label: 'Tahun Program',
                        type: 'select',
                        validation: ['required'],
                        option: {
                          default_label: fcff.program_year,
                          multiple: true,
                          default_options: [
                            {
                              label: '2020',
                              code: '2020',
                            },
                            {
                              label: '2021',
                              code: '2021',
                            },
                            {
                              label: '2022',
                              code: '2022',
                            },
                            {
                              label: '2023',
                              code: '2023',
                            },
                            {
                              label: '2024',
                              code: '2024',
                            },
                          ],
                          list_pointer: {
                            label: 'label',
                            code: 'code',
                            display: ['label'],
                          },
                        },
                      }},model:{value:(fcff.program_year),callback:function ($$v) {_vm.$set(fcff, "program_year", $$v)},expression:"fcff.program_year"}})],1),_c('v-col',{attrs:{"lg":"5"}},[_c('geko-input',{attrs:{"item":{
                        type: 'select',
                        label: 'Field Coordinator',
                        validation: ['required'],
                        api: 'getEmployeeList_new',
                        default_label: fcff.employees_name,
                        option: {
                          list_pointer: {
                            code: 'nik',
                            label: 'name',
                            display: ['name'],
                          },
                        },
                      }},model:{value:(fcff.key1),callback:function ($$v) {_vm.$set(fcff, "key1", $$v)},expression:"fcff.key1"}})],1),_c('v-col',{staticClass:"mt-0 pt-0 d-flex flex-column",staticStyle:{"justify-content":"center","position":"relative","transform":"translateY(15%)"},attrs:{"lg":"1"}},[_c('v-btn',{staticClass:"btn-icon",attrs:{"small":"","variant":"danger"},on:{"click":function($event){return _vm.removeFcFf(i)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete-empty")])],1)],1)],1)],1)}),_c('v-col',{attrs:{"lg":"12"}},[(_vm.error_fc_ff)?_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.error_fc_ff)+" ")]):_vm._e()]),_c('v-col',{staticClass:"form-separator",attrs:{"lg":"12"}},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('p',{staticClass:"mb-0"},[_vm._v("Pemetaan Area Kerja")]),_c('v-btn',{staticClass:"add-button ml-2",attrs:{"variant":"success","small":""},on:{"click":function($event){return _vm.addWorkingArea()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus")])],1)],1)]),_vm._l((_vm.working_areas),function(area,i){return _c('v-col',{key:'area-' + i,attrs:{"lg":"12"}},[_c('v-row',{staticClass:"mx-3 bg-grey"},[_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                        label: 'Tahun Program',
                        type: 'select',
                        validation: ['required'],
                        option: {
                          default_label: area.program_year,
                          multiple: true,
                          default_options: [
                            {
                              label: '2020',
                              code: '2020',
                            },
                            {
                              label: '2021',
                              code: '2021',
                            },
                            {
                              label: '2022',
                              code: '2022',
                            },
                            {
                              label: '2023',
                              code: '2023',
                            },
                            {
                              label: '2024',
                              code: '2024',
                            },
                          ],
                          list_pointer: {
                            label: 'label',
                            code: 'code',
                            display: ['label'],
                          },
                        },
                      }},model:{value:(area.program_year),callback:function ($$v) {_vm.$set(area, "program_year", $$v)},expression:"area.program_year"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                        type: 'select',
                        label: 'Management Unit',
                        api: 'GetManagementUnit',
                        validation: ['required'],
                        // param: {
                        //   program_year: area.program_year,
                        // },
                        option: {
                          getterKey: 'data.result',
                          default_label: area.managementunits_name,
                          list_pointer: {
                            label: 'name',
                            code: 'mu_no',
                            display: ['name'],
                          },
                        },
                      }},model:{value:(area.mu_no),callback:function ($$v) {_vm.$set(area, "mu_no", $$v)},expression:"area.mu_no"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                        type: 'select',
                        label: 'Target Area',
                        api: 'GetTargetArea',
                        validation: ['required'],
                        param: {
                          mu_no: area.mu_no,
                        },
                        option: {
                          default_label: area.target_areas_name,
                          getterKey: 'data.result',
                          list_pointer: {
                            label: 'name',
                            code: 'area_code',
                            display: ['name'],
                          },
                        },
                      },"disabled":!area.mu_no},model:{value:(area.area_code),callback:function ($$v) {_vm.$set(area, "area_code", $$v)},expression:"area.area_code"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
                        type: 'select',
                        label: 'Working Area / Desa',
                        api: 'GetDesaByKecamatanTA_new',
                        validation: ['required'],
                        param: {
                          ta_no: area.area_code,
                          program_year:
                            Array.isArray(area.program_year) &&
                            area.program_year.length > 0
                              ? area.program_year.join(',')
                              : '',
                        },
                        option: {
                          // getterKey: 'data.result',
                          default_label: area.desas_name,
                          list_pointer: {
                            label: 'name',
                            code: 'kode_desa',
                            display: ['name'],
                          },
                        },
                      },"disabled":!area.area_code ||
                        !Array.isArray(area.program_year) ||
                        (Array.isArray(area.program_year) &&
                          area.program_year.length == 0)},model:{value:(area.kode_desa),callback:function ($$v) {_vm.$set(area, "kode_desa", $$v)},expression:"area.kode_desa"}})],1),_c('v-col',{staticClass:"mt-0 pt-0 d-flex flex-column",staticStyle:{"justify-content":"center","position":"relative","transform":"translateY(15%)"},attrs:{"lg":"1"}},[_c('v-btn',{staticClass:"btn-icon",attrs:{"small":"","variant":"danger"},on:{"click":function($event){return _vm.removeWorkingArea(i)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete-empty")])],1)],1)],1)],1)}),_c('v-col',{attrs:{"lg":"12"}},[(_vm.error_working_area)?_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.error_working_area)+" ")]):_vm._e()])],2):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-row justify-content-center",attrs:{"lg":"12"}},[_c('v-btn',{staticClass:"d-flex flex-row align-items-center",attrs:{"variant":"warning","type":"submit"}},[_c('v-icon',[_vm._v("mdi-pencil-outline")]),_c('span',[_vm._v("Perbarui Data FF")])],1)],1)],1),_c('v-row')],1)]}}],null,true)})],1)],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})
}
var staticRenderFns = []

export { render, staticRenderFns }