export default {
  gender: [
    {
      code: "islam",
      label: "Islam",
    },
    {
      code: "kristen",
      label: "Kristen",
    },
    {
      code: "katholik",
      label: "Katholik",
    },
    {
      code: "hindu",
      label: "Hindu",
    },
    {
      code: "budha",
      label: "Budha",
    },
  ],
};
