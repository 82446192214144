var render = function render(){var _vm=this,_c=_vm._self._c;return _c('geko-base-crud',{attrs:{"config":_vm.config,"hideUpdate":!['13', '4', '19', '20'].includes(_vm.$store.state.User.role),"refreshKey":_vm.refreshKey,"hideDelete":!['13', '4'].includes(_vm.$store.state.User.role),"hideCreate":['19'].includes(_vm.$store.state.User.role)},on:{"create-success":function($event){return _vm.onCreateSuccess($event)}},scopedSlots:_vm._u([{key:"list-name",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"d-block badge bg-light"},[_vm._v(_vm._s(item.ff_no))])])])]}},{key:"list-indicator",fn:function({ item }){return [_c('div',{staticClass:"indicator-wrapper pt-1"},[_c('div',{staticClass:"indicator",class:{
          danger: item.active == 0,
          success: item.active == 1,
        }})])]}},{key:"list-bottom-action",fn:function({ item }){return [(_vm.$_sys.isAllowed('field-facilitator-update'))?_c('v-btn',{staticClass:"d-flex flex-row align-items-center mt-2",attrs:{"variant":"info","small":""},on:{"click":function($event){return _vm.onAssignProgramYear(item)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-account-switch")]),_c('span',[_vm._v("Assign")])],1):_vm._e()]}},{key:"list-main_pivots_program_year",fn:function({ item }){return [(item.main_pivots_program_year)?_c('div',{staticClass:"d-flex flex-row list-program-year justify-content-center",staticStyle:{"width":"150px","flex-wrap":"wrap"}},_vm._l((item.main_pivots_program_year
          .replace(/ /g, '')
          .split(',')
          .map((v) => parseInt(v))
          .sort(function (a, b) {
            return a - b;
          })),function(item,i){return _c('span',{staticClass:"badge bg-custom mr-1 mb-1",class:{
          [`y${item}`]: true,
        }},[_vm._v(_vm._s(item))])}),0):_vm._e()]}},{key:"list-email",fn:function({ item }){return [(
        typeof item.ff_users_data === 'object' &&
        item.ff_users_data !== null &&
        item.ff_users_data.email
      )?_c('div',[_c('span',[_vm._v(_vm._s(item.ff_users_data.email))])]):_c('div',[_vm._v("-")])]}},{key:"list-after-filter",fn:function(){return [_c('ff-assign-modal',{attrs:{"data":_vm.ffData,"dataKey":_vm.ffDataKey},on:{"success":function($event){_vm.refreshKey = _vm.refreshKey + 1}}}),_c('ff-email-modal',{attrs:{"data":_vm.emailData,"dataKey":_vm.emailDataKey},on:{"success":function($event){_vm.refreshKey = _vm.refreshKey + 1}}})]},proxy:true},{key:"detail-main_pivots_program_year",fn:function({ item }){return [(item.main_pivots_program_year)?_c('div',{staticClass:"d-flex flex-row list-program-year"},_vm._l((item.main_pivots_program_year
          .replace(/ /g, '')
          .split(',')
          .map((v) => parseInt(v))
          .sort(function (a, b) {
            return a - b;
          })),function(item,i){return _c('span',{staticClass:"badge bg-custom mr-1 mb-1",class:{
          [`y${item}`]: true,
        }},[_vm._v(_vm._s(item))])}),0):_c('div',[_vm._v("-")])]}},{key:"detail-email",fn:function({ item }){return [(
        typeof item.ff_users_data === 'object' &&
        item.ff_users_data !== null &&
        item.ff_users_data.email
      )?_c('div',[_c('span',[_vm._v(_vm._s(item.ff_users_data.email))])]):_c('div',[_vm._v("-")])]}},{key:"create-city",fn:function({ formData, setFormData, item }){return [_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'Kota / Kabupaten',
          api: 'GetKabupaten',
          validation: ['required'],
          param: {
            province_code: formData.province,
          },
          option: {
            getterKey: 'data.result',
            list_pointer: {
              label: 'name',
              code: 'kabupaten_no',
              display: ['name'],
            },
          },
        },"disabled":!formData.province},model:{value:(formData.city),callback:function ($$v) {_vm.$set(formData, "city", $$v)},expression:"formData.city"}})],1)]}},{key:"create-kecamatan",fn:function({ formData, setFormData, item }){return [_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'Kecamatan',
          api: 'GetKecamatan',
          validation: ['required'],
          param: {
            kabupaten_no: formData.city,
          },
          option: {
            getterKey: 'data.result',
            list_pointer: {
              label: 'name',
              code: 'kode_kecamatan',
              display: ['name'],
            },
          },
        },"disabled":!formData.city},model:{value:(formData.kecamatan),callback:function ($$v) {_vm.$set(formData, "kecamatan", $$v)},expression:"formData.kecamatan"}})],1)]}},{key:"create-village",fn:function({ formData, setFormData, item }){return [_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'Desa',
          api: 'GetDesa',
          validation: ['required'],
          param: {
            kode_kecamatan: formData.kecamatan,
          },
          option: {
            getterKey: 'data.result',
            list_pointer: {
              label: 'name',
              code: 'kode_desa',
              display: ['name'],
            },
          },
        },"disabled":!formData.kecamatan},model:{value:(formData.village),callback:function ($$v) {_vm.$set(formData, "village", $$v)},expression:"formData.village"}})],1)]}},{key:"create-mu_no",fn:function({ formData, setFormData, item }){return [_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("AREA KERJA")])]),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'Management Unit',
          api: 'GetManagementUnit',
          validation: ['required'],
          param: {
            program_year: formData.program_year,
          },
          option: {
            getterKey: 'data.result',
            list_pointer: {
              label: 'name',
              code: 'mu_no',
              display: ['name'],
            },
          },
        },"disabled":!formData.program_year},model:{value:(formData.mu_no),callback:function ($$v) {_vm.$set(formData, "mu_no", $$v)},expression:"formData.mu_no"}})],1)]}},{key:"create-target_area",fn:function({ formData, setFormData, item }){return [_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'Target Area',
          api: 'GetTargetArea',
          validation: ['required'],
          param: {
            program_year: formData.program_year,
            mu_no: formData.mu_no,
          },
          option: {
            getterKey: 'data.result',
            list_pointer: {
              label: 'name',
              code: 'area_code',
              display: ['name'],
            },
          },
        },"disabled":!formData.mu_no},model:{value:(formData.target_area),callback:function ($$v) {_vm.$set(formData, "target_area", $$v)},expression:"formData.target_area"}})],1)]}},{key:"create-working_area",fn:function({ formData, setFormData, item }){return [_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'Working Area / Desa',
          api: 'GetDesa',
          validation: ['required'],
          param: {
            program_year: formData.program_year,
            kode_ta: formData.target_area,
          },
          option: {
            getterKey: 'data.result',
            list_pointer: {
              label: 'name',
              code: 'kode_desa',
              display: ['name'],
            },
          },
        },"disabled":!formData.target_area},model:{value:(formData.working_area),callback:function ($$v) {_vm.$set(formData, "working_area", $$v)},expression:"formData.working_area"}})],1)]}},{key:"create-fc_no",fn:function({ formData, setFormData, item }){return [_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'PIC T4T (Field Coordinator)',
          api: 'getEmployeeList_new',
          validation: ['required'],
          param: {
            program_year: formData.program_year,
          },
          option: {
            // getterKey: 'data.result.data',
            list_pointer: {
              label: 'name',
              code: 'nik',
              display: ['name'],
            },
          },
        }},model:{value:(formData.fc_no),callback:function ($$v) {_vm.$set(formData, "fc_no", $$v)},expression:"formData.fc_no"}})],1)]}},{key:"update-city",fn:function({ formData, setFormData, item }){return [_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'Kota / Kabupaten',
          api: 'GetKabupaten',
          validation: [],
          default_label: formData.kabupatens_name_domicile,
          param: {
            province_code: formData.province,
          },
          option: {
            getterKey: 'data.result',
            list_pointer: {
              label: 'name',
              code: 'kabupaten_no',
              display: ['name'],
            },
          },
        },"disabled":!formData.province},model:{value:(formData.city),callback:function ($$v) {_vm.$set(formData, "city", $$v)},expression:"formData.city"}})],1)]}},{key:"update-kecamatan",fn:function({ formData, setFormData, item }){return [_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'Kecamatan',
          api: 'GetKecamatan',
          validation: [],
          default_label: formData.kecamatans_name_domicile,
          param: {
            kabupaten_no: formData.city,
          },
          option: {
            getterKey: 'data.result',
            list_pointer: {
              label: 'name',
              code: 'kode_kecamatan',
              display: ['name'],
            },
          },
        },"disabled":!formData.city},model:{value:(formData.kecamatan),callback:function ($$v) {_vm.$set(formData, "kecamatan", $$v)},expression:"formData.kecamatan"}})],1)]}},{key:"update-village",fn:function({ formData, setFormData, item }){return [_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'Desa',
          api: 'GetDesa',
          validation: [],
          default_label: formData.desas_name_domicile,
          param: {
            kode_kecamatan: formData.kecamatan,
          },
          option: {
            getterKey: 'data.result',
            list_pointer: {
              label: 'name',
              code: 'kode_desa',
              display: ['name'],
            },
          },
        },"disabled":!formData.kecamatan},model:{value:(formData.village),callback:function ($$v) {_vm.$set(formData, "village", $$v)},expression:"formData.village"}})],1)]}},{key:"update-mu_no",fn:function({ formData, setFormData, item }){return [_c('v-col',{staticClass:"form-separator",attrs:{"md":"12"}},[_c('h4',[_vm._v("AREA KERJA")])]),_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'Management Unit',
          api: 'GetManagementUnit',
          validation: ['required'],
          default_label: formData.managementunits_name,
          param: {
            program_year: formData.program_year,
          },
          option: {
            getterKey: 'data.result',
            list_pointer: {
              label: 'name',
              code: 'mu_no',
              display: ['name'],
            },
          },
        }},model:{value:(formData.mu_no),callback:function ($$v) {_vm.$set(formData, "mu_no", $$v)},expression:"formData.mu_no"}})],1)]}},{key:"update-target_area",fn:function({ formData, setFormData, item }){return [_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'Target Area',
          api: 'GetTargetArea',
          validation: ['required'],
          default_label: formData.target_areas_name,
          param: {
            program_year: formData.program_year,
            mu_no: formData.mu_no,
          },
          option: {
            getterKey: 'data.result',
            list_pointer: {
              label: 'name',
              code: 'area_code',
              display: ['name'],
            },
          },
        },"disabled":!formData.mu_no},model:{value:(formData.target_area),callback:function ($$v) {_vm.$set(formData, "target_area", $$v)},expression:"formData.target_area"}})],1)]}},{key:"update-working_area",fn:function({ formData, setFormData, item }){return [_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'Working Area / Desa',
          api: 'GetDesa',
          validation: ['required'],
          default_label: formData.desas_name,
          param: {
            program_year: formData.program_year,
            kode_ta: formData.target_area,
          },
          option: {
            getterKey: 'data.result',
            list_pointer: {
              label: 'name',
              code: 'kode_desa',
              display: ['name'],
            },
          },
        },"disabled":!formData.target_area},model:{value:(formData.working_area),callback:function ($$v) {_vm.$set(formData, "working_area", $$v)},expression:"formData.working_area"}})],1)]}},{key:"update-fc_no",fn:function({ formData, setFormData, item }){return [_c('v-col',{attrs:{"lg":"6"}},[_c('geko-input',{attrs:{"item":{
          type: 'select',
          label: 'PIC T4T (Field Coordinator)',
          api: 'getEmployeeList_new',
          validation: ['required'],
          default_label: formData.employees_name,
          param: {
            program_year: formData.program_year,
          },
          option: {
            // getterKey: 'data.result.data',
            list_pointer: {
              label: 'name',
              code: 'nik',
              display: ['name'],
            },
          },
        },"disabled":true},model:{value:(formData.fc_no),callback:function ($$v) {_vm.$set(formData, "fc_no", $$v)},expression:"formData.fc_no"}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }