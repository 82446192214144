var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"50%"},scopedSlots:_vm._u([{key:"default",fn:function({ isActive }){return [_c('v-card',[_c('v-card-title',[_vm._v("Perbarui Email FF ")]),_c('v-card-text',{staticClass:"ff-email-wrapper"},[_c('ValidationObserver',{ref:"firstForm",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                    type: 'text',
                    label: 'Nama FF',
                    validation: ['required'],
                  },"disabled":true},model:{value:(_vm.formData.tmp_name),callback:function ($$v) {_vm.$set(_vm.formData, "tmp_name", $$v)},expression:"formData.tmp_name"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                    type: 'text',
                    label: 'Kode FF',
                    validation: ['required'],
                  },"disabled":true},model:{value:(_vm.formData.ff_no),callback:function ($$v) {_vm.$set(_vm.formData, "ff_no", $$v)},expression:"formData.ff_no"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('geko-input',{attrs:{"item":{
                    type: 'text',
                    label: 'Email',
                    validation: ['required'],
                    placeholder: 'Masukkan email baru tanpa tanda @',
                    append: '@t4t.org',
                  }},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"d-flex flex-row justify-content-center"},[_c('v-btn',{staticClass:"d-flex flex-row align-items-center",attrs:{"variant":"warning","type":"submit","disabled":_vm.loading}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-email-edit-outline")]),_c('span',[_vm._v("Perbarui Email FF")])],1)],1)])],1)],1)]}}],null,true)})],1)],1)]}}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}})
}
var staticRenderFns = []

export { render, staticRenderFns }